/**
 * Created by ProSite Media Inc.
 * Developer: Salvatore Boettcher
 * Date/Time: 1/6/2017 - 4:21 PM
 * Project: carlosadmin
 * IDE Program:  PhpStorm
 */
$().ready(function(){
    $('.flip').click(function(){
        $(this).find('.card').toggleClass('flipped');
        console.log(this);
    });

});

